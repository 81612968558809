import React from "react"
import * as Chakra from "@chakra-ui/react"
import Logo from "../images/logo.svg"
import { graphql, useStaticQuery } from "gatsby"
import { Seo } from "../components/seo"

const Thankyou = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        buildTime
      }
      wp {
        acfOptionsOptions {
          siteOptions {
            actionText
            description
            desktopBackgroundImage {
              localFile {
                publicURL
              }
            }
            mobileBackgroundImage {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Chakra.Box
      width="100%"
      height="100vh"
      backgroundImage={`url(${data.wp.acfOptionsOptions.siteOptions.desktopBackgroundImage?.localFile?.publicURL})`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      display="flex"
      position="relative"
      alignItems="flex-start"
      flexDirection="column"
      backgroundColor="var(--dl-color-gray-black)"
      marginTop="-140px"
      css={{
        "@media (max-width: 767px)": {
          height: "auto",
          alignItems: "center",
          marginTop: "-100px",
          backgroundImage: `url(${data.wp.acfOptionsOptions.siteOptions.mobileBackgroundImage?.localFile?.publicURL})`,
        },
        "@media (max-width: 479px)": {
          justifyContent: "center",
        },
      }}
    >
      <Seo
        title={data.wp.acfOptionsOptions.siteOptions.actionText}
        location={location}
      />

      <Chakra.VStack
        width="100%"
        maxWidth="1440px"
        ml="auto"
        mr="auto"
        justify="start"
        align="start"
        pl={{ base: "24px", lg: "58px" }}
        pr={{ base: "24px", lg: "58px" }}
        pt="547px"
        display={{ base: "none", md: "flex" }}
      >
        <Chakra.Text
          textTransform="uppercase"
          maxWidth="250px"
          width="100%"
          fontSize="40px"
          color="#fff"
          lineHeight="46px"
          letterSpacing="4px"
          fontFamily="TradeGothic LT Extended"
          pb="28px"
        >
          {data.wp.acfOptionsOptions.siteOptions.actionText}
        </Chakra.Text>
        <Chakra.HStack
          width="100%"
          justify="space-between"
          align="center"
          pb="57px"
        >
          <Chakra.Text
            fontSize="14px"
            lineHeight="22px"
            color="#fff"
            maxWidth="335px"
          >
            {data.wp.acfOptionsOptions.siteOptions.description}
          </Chakra.Text>
          <Chakra.Link
            textDecoration="none"
            color="#fff"
            fontSize="11px"
            lineHeight="12px"
            letterSpacing="1.8px"
            onClick={() => window.history.back()}
            fontFamily="TradeGothic LT Extended"
            textTransform="uppercase"
          >
            Back
          </Chakra.Link>
        </Chakra.HStack>
      </Chakra.VStack>
      <Chakra.VStack
        width="100%"
        maxWidth="1440px"
        ml="auto"
        mr="auto"
        justify="start"
        align="start"
        pl={{ base: "24px", lg: "58px" }}
        pr={{ base: "24px", lg: "58px" }}
        pt="142px"
        display={{ base: "flex", md: "none" }}
      >
        <Chakra.VStack width="100%" justify="start" align="start" pb="424px">
          <Chakra.Text
            textTransform="uppercase"
            maxWidth="170px"
            width="100%"
            fontSize="26px"
            color="#fff"
            lineHeight="34px"
            letterSpacing="2.6px"
            fontFamily="TradeGothic LT Extended"
            pb="20px"
          >
            {data.wp.acfOptionsOptions.siteOptions.actionText}
          </Chakra.Text>
          <Chakra.Text
            fontSize="14px"
            lineHeight="22px"
            color="#fff"
            maxWidth="260px"
          >
            {data.wp.acfOptionsOptions.siteOptions.description}
          </Chakra.Text>
        </Chakra.VStack>
        <Chakra.VStack width="100%" justify="center" align="center" pb="59px">
          <Chakra.Box width="100%" height="52px" mb="20px">
            <Logo width="100%" height="100%" />
          </Chakra.Box>
          <Chakra.Link
            textDecoration="none"
            color="#fff"
            fontSize="11px"
            lineHeight="12px"
            letterSpacing="1.8px"
            onClick={() => window.history.back()}
            fontFamily="TradeGothic LT Extended"
            textTransform="uppercase"
          >
            Back
          </Chakra.Link>
        </Chakra.VStack>
      </Chakra.VStack>
    </Chakra.Box>
  )
}

export default Thankyou
